import React from "react";
import {NavLink} from "react-router-dom";
import '../App.css';


const Footer = () =>{
    return(
       
        <div className = "footer">
            {/**Links */}
             <div className = "footerlinks">
                {/* Link Impressum */}
                <NavLink to = {"/Impressum"}>
                Impressum
                </NavLink>
                {/* Link Datenschutz */}
                <NavLink to = {"/Datenschutz"}>
                Datenschutz*
                </NavLink>
               
            </div>
            {/** Icons */}
            <div className = "footericons">
                <a href = "https://www.linkedin.com/company/enmatch/">
                <img src={require("../assets/linkedin.png")} alt = "linkedin icon"></img>
                </a>
            </div>
        </div>
    )
}

export default Footer;